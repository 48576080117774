<template>
  <div>
    <div class="rank-title">
      <span>{{dwType}}单位</span>
      <span class="rank-title-top10">TOP10</span>
    </div>
    <div class="info-list">
      <div v-for="(item,index) in data" :key="item.id" class="info-list-item">
        <div class="info-list-field">
          {{index+1}}、{{item.name}}
        </div>
        <div class="info-list-field info-list-field-fixed link" style="width: 80px;" @click="openList(item.name)">{{item.value}}场</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "ZhanhuiRankedByDw",
  props:{
    params:Object,
    dwType:String
  },
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      const data=(await api.getZhanhuiDwStat({
        dwType:this.dwType,
        ...this.params,
        limit:10
      })).data
      this.data=data
    },
    openList(name){
      this.$router.push({path:'/zhanhui/list',query:{...this.dwType==='主办'?{zbdw:name}:{cbdw:name},...this.params}})
    }
  }
}
</script>

<style scoped>

</style>
